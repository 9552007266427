import React from "react";
import "../NavBar/navbar.css";
import firstimg from "../../Assets/image1.svg";
import secondimg from "../../Assets/image2.svg";
import thirdimg from "../../Assets/image3.svg";
import userpic from "../../Assets/userpic.svg";

const Navbar = () => {
  return (
    <div
      className="navContainer"
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        width: "4%",
        height: "100vh",
        alignItems: "center",
        borderRadius: "5px",
        boxShadow: "5px 0px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div className="elements">
        <img src={firstimg} alt="navimgf" />
      </div>
      <div className="elements">
        <img src={secondimg} alt="navimgs" />
      </div>
      <div className="elements">
        <img src={thirdimg} alt="navimgt" />
      </div>
      {/* <div style={{ marginTop: "auto" }}>
        <img src={userpic} alt="navimgt" width={45} height={45} />
      </div> */}
    </div>
  );
};

export default Navbar;
