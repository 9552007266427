import React, { useState } from 'react';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';  // You'll need to install react-icons if you haven't already

const PasswordPrompt = ({ onPasswordSuccess }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handlePasswordCheck = async () => {
    try {
      const response = await axios.post(`${apiUrl}/verify-password`, { password });
      if (response.data.access === 'granted') {
        sessionStorage.setItem('authenticated', 'true');
        onPasswordSuccess();
      } else {
        setError(true);
      }
    } catch (error) {
      setError('Unauthorized Access! Please try again.');
    }
  };

  return (
    <div style={{
      position: 'absolute', 
      top: '50%', 
      left: '50%', 
      transform: 'translate(-50%, -50%)', 
      textAlign: 'center',
      width: '300px',
      padding: '20px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      borderRadius: '10px',
      background: '#fff',
    }}>
      <h1 style={{ color: '#667085', marginBottom: '20px' }}>Enter Password</h1>
      <div style={{
        position: 'relative',
        marginBottom: '10px',
      }}>
        <input
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{
            width: '93%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc'
          }}
        />
        <button
          onClick={() => setShowPassword(!showPassword)}
          style={{
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </button>
      </div>
      <button
        onClick={handlePasswordCheck}
        style={{
          width: '35%',
          padding: '10px',
          borderRadius: '5px',
          border: 'none',
          backgroundColor: '#4386FB',
          color: 'white',
          fontWeight: '600',
          cursor: 'pointer'
        }}
      >
        Submit
      </button>
      {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
    </div>
  );
};

export default PasswordPrompt;

























// import React, { useState } from 'react';
// import axios from 'axios';


// const PasswordPrompt = ({ onPasswordSuccess }) => {
//   const [password, setPassword] = useState("");
//   const [error, setError] = useState(false);

//   const apiUrl = process.env.REACT_APP_API_URL;

//   const handlePasswordCheck = async () => {
//     try {
//       const response = await axios.post(`${apiUrl}/verify-password`, { password });
//       if (response.data.access === 'granted') {
//         sessionStorage.setItem('authenticated', 'true');
//         onPasswordSuccess();
//       } else {
//         setError(true);
//         // setPassword('');
//       }
//     } catch (error) {
//       setError('Unauthorized Access! Please try again.');
//       // setPassword('');
//     }
//   };

//   return (
//     <div style={{
//       position: 'absolute', 
//       top: '50%', 
//       left: '50%', 
//       transform: 'translate(-50%, -50%)', 
//       textAlign: 'center',
//       width: '300px',
//       padding: '20px',
//       boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
//       borderRadius: '10px',
//       background: '#fff',
//     }}>
//       <h1 style={{ color: '#667085', marginBottom: '20px' }}>Enter Password</h1>
//       <input
//         type="password"
//         value={password}
//         onChange={(e) => setPassword(e.target.value)}
//         style={{
//           width: '92%',
//           padding: '10px',
//           marginBottom: '10px',
//           borderRadius: '5px',
//           border: '1px solid #ccc'
//         }}
//       />
//       <button
//         onClick={handlePasswordCheck}
//         style={{
//           width: '30%',
//           padding: '10px',
//           borderRadius: '5px',
//           border: 'none',
//           backgroundColor: '#4386FB',
//           color: 'white',
//           fontWeight: '600',
//           cursor: 'pointer'
//         }}
//       >
//         Submit
//       </button>
//       {error && <p style={{ color: 'red', marginTop: '10px' }}>Incorrect Password..!</p>}
//     </div>
//   );
// };

// export default PasswordPrompt;





