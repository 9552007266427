import React from "react";
import "./displayModelInfo.css";

const ModelSummary = ({ model }) => (
  <img
    src={`data:image/jpeg;base64,${model.image_data_base64}`}
    alt="Model Summary"
    style={{
      width: "100%",
      height: "auto",
      maxWidth: "600px",
      borderRadius: "10px",
    }}
  />
);
export default ModelSummary;
