import React, { useState } from "react";
import "./displayModelInfo.css";
import ModelInfo from "./ModelInfo";
import BenchmarkInfo from "./BenchmarkInfo";
import Notes from "./Notes";
import ModelSummary from "./ModelSummary";

const DisplayModelInfo = ({ model, onRowClose }) => {
  const [activeTab, setActiveTab] = useState("Model_Info");

  const tabs = {
    Model_Info: <ModelInfo model={model} />,
    Benchmark_Info: <BenchmarkInfo model={model} />,
    Notes: <Notes model={model}/>,
    Model_Summary: <ModelSummary model={model} />,
  };

  return (
    <div className="model-details">
      <div className="model-details-contents">
        <div className="model-details-tabs">
          {Object.keys(tabs).map((tab) => (
            <div
              key={tab}
              className={`model-details-tab ${
                activeTab === tab ? "active" : ""
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab.replace("_", " ")}
            </div>
          ))}
        </div>

        <div className="model-details-tab-content">{tabs[activeTab]}</div>
      </div>
      <button className="cls-btn" onClick={onRowClose}>
        Close
      </button>
    </div>
  );
};

export default DisplayModelInfo;
