import React from "react";
import heading from "../../Assets/ModelArtifact.svg";

const Title = () => {
  return (
    <div>
      <img src={heading} alt="titleimg" style={{ padding: "1.3%" }} />
    </div>
  );
};

export default Title;
