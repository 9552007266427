import React, { useEffect, useState } from "react";
import axios from "axios";
import Title from "../Title_Logo/Title";
import ModelCategory from "../Model_Tabs/ModelCategory";
import ModelTable from "../Model_Table/ModelTable";
import "../Model_Table/modelTable.css";
import DisplayModelInfo from "../Model_Information/DisplayModelInfo";
import "../Root_Artifact/modelArtifact.css";

const ModelArtifact = () => {
  const [models, setModels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedModel, setSelectedModel] = useState(null); // State to hold the selected model
  const [filteredModels, setFilteredModels] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [categoryCounts, setCategoryCounts] = useState({});

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchModels();
  }, []);

  useEffect(() => {
    filterModels();
  }, [selectedCategory, models]);

  const fetchModels = async () => {
    try {
      const response = await axios.get(`${apiUrl}/models`);
      const sortedModels = response.data.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      ); // Sort by timestamp in descending order
      setModels(sortedModels);
      console.log('res..',sortedModels);
      updateCategoryCounts(sortedModels);
    } catch (error) {
      console.error("Error fetching models:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateCategoryCounts = (models) => {
    const counts = models.reduce((acc, model) => {
      const { category } = model;
      if (acc[category]) {
        acc[category]++;
      } else {
        acc[category] = 1;
      }
      return acc;
    }, {});
    setCategoryCounts(counts);
  };

  const filterModels = () => {
    if (selectedCategory === "All") {
      setFilteredModels(models);
    } else {
      setFilteredModels(
        models.filter(
          (model) =>
            model.category.toLowerCase() === selectedCategory.toLowerCase()
        )
      );
    }
  };

  const handleRowClick = (model) => {
    setSelectedModel(model);
  };

  const handleRowClose = () => {
    setSelectedModel(null);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div
      style={{
        marginLeft: "1%",
        borderRadius: "5px",
        border: "1px solid #fff",
        width: "94%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        height: "99vh",
      }}
    >
      <Title />
      <ModelCategory
        modeldata={models}
        refresh={fetchModels}
        onCategoryChange={handleCategoryChange}
        categoryCounts={categoryCounts}
      />
      <div style={{ display: "flex", width: "100%"}} className="model-info">
        <div
          style={{ width: selectedModel ? "70%" : "100%", position: 'relative', zIndex:'9999' }}
          className="model-info"
        >
          <ModelTable
            models={filteredModels}
            isLoading={isLoading}
            onRowClick={handleRowClick}
            selectedModel={selectedModel}
            fetchModel={fetchModels}
          />
        </div>
        {selectedModel && (
          <div className="model-info" style={{ width: "39%", height: "57vh" }}>
            <DisplayModelInfo
              model={selectedModel}
              onRowClose={handleRowClose}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ModelArtifact;
