import React from "react";
import "./displayModelInfo.css";

  // Helper function to safely parse JSON
  const safeParseJSON = (jsonString) => {
    try {
      // console.log("test parsed data...",JSON.parse(jsonString))
      return JSON.parse(jsonString);
    } catch (e) {
      console.error("Failed to parse JSON", e);
      return {};
    }
  };

  // Dynamically find the primary key for model details
  const findPrimaryKey = (metadata) => {
    const commonKeys = ["slt", "genie", "stt","tem","tts","history","benchmarks_results"]; // Extended list based on keys
    return Object.keys(metadata).find(key => commonKeys.includes(key)) || Object.keys(metadata)[0];
  };

const ModelInfo = ({ model }) => {
  // const metadata = JSON.parse(model.model_metadata).slt;
  const metadata = safeParseJSON(model.model_metadata);
  const primaryKey = findPrimaryKey(metadata);

  if (!metadata[primaryKey]) {
    return <div>No Benchmark Data Available</div>;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", overflowX:'scroll'}}>
      {Object.entries(metadata[primaryKey] || {}).map(([key, value]) => {
        // Check if value is an object to handle nested data
        if (typeof value === 'object' && value !== null) {
          return (
            <div key={key}>
              <label>{key.replace(/_/g, " ")}</label>
              {Object.entries(value).map(([subKey, subValue]) => (
                <p className="detail-values" key={subKey}>
                  <label>{subKey.replace(/_/g, " ")}</label> <span style={{color:'#667085'}}>{subValue.toString()}</span>
                </p>
              ))}
            </div>
          );
        } else {
          return (
            <p className="detail-values" key={key}>
              <label>{key.replace(/_/g, " ")}</label> <span style={{color:'#667085'}}>{value.toString()}</span>
            </p>
          );
        }
      })}
    </div>
  );
};

export default ModelInfo;



