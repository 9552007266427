import React, { useState } from "react";
import axios from "axios";
import "../Add_Model/addModel.css";
import hfive from "../../Assets/hfiveIcon.svg";
import addIcon from "../../Assets/addIcon.svg";
import metadata from "../../Assets/metadata.svg";
import summary from "../../Assets/summary.svg";
import Loader from "../Loader/Loader";

const AddModel = ({ handleClose, refresh }) => {
  const [modelName, setModelName] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [category, setCategory] = useState("");
  const [modelFile, setModelFile] = useState(null);
  const [metaFile, setMetaFile] = useState(null);
  const [summaryFile, setSummaryFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleModelAddIconClick = () => {
    document.getElementById("modelInput").click();
  };

  const handleMetaAddIconClick = () => {
    document.getElementById("metaInput").click();
  };

  const handleSummaryAddIconClick = () => {
    document.getElementById("summaryInput").click();
  };

  const handleModelChange = (e) => {
    setModelFile(e.target.files[0]);
  };

  const handleMetaChange = (e) => {
    setMetaFile(e.target.files[0]);
  };

  const handleSummaryChange = (e) => {
    setSummaryFile(e.target.files[0]);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!modelName.trim()) {
      newErrors.modelName = "Model name is required *";
    }

    if (!createdBy.trim()) {
      newErrors.createdBy = "Created by is required *";
    }

    if (!category.trim() || category === "Select Model Category") {
      newErrors.category = "Please select a category *";
    }

    if (!modelFile) {
      newErrors.modelFile = "Please upload the model file *";
    }

    if (!metaFile) {
      newErrors.metaFile = "Please upload the metadata file *";
    }

    if (!summaryFile) {
      newErrors.summaryFile = "Please upload the summary file *";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  //handle submit
  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("model_name", modelName);
    formData.append("created_by", createdBy);
    formData.append("category", category);
    formData.append("model", modelFile);
    formData.append("meta_data", metaFile);
    formData.append("summary_image", summaryFile);

    try {
      const response = await axios.post(`${apiUrl}/uploadmodels`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        refresh();
        handleClose(); // Close the modal after successful upload
      }
    } catch (error) {
      console.error("Error uploading model:", error.response);
      if (error.response && error.response.status === 400) {
        alert(error.response.data.detail); // Show alert with the error message from the server
      } else {
        alert("An error occurred while uploading the model. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="add-model-container">
      {isLoading ? (
        <Loader />
      ) : (
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{ textAlign: "center", width: "100%", marginBottom: "20px" }}
          >
            <h3
              style={{
                color: "#4386FB",
                fontSize: "20px",
                fontWeight: 600,
                margin: "20px",
              }}
            >
              Add Model
            </h3>
          </div>

          <div
            style={{
              flex: "1 1 33%",
              marginBottom: "30px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label className="form-label">Model Name</label>
            <input
              type="text"
              placeholder="Enter Model Name"
              className="custom-input"
              name="modelName"
              value={modelName}
              onChange={(e) => setModelName(e.target.value)}
            />
            {errors.modelName && (
              <span
                style={{ color: "red", fontSize: "12px", marginLeft: "5px" }}
              >
                {errors.modelName}
              </span>
            )}
          </div>

          <div
            style={{
              flex: "1 1 33%",
              marginBottom: "30px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label className="form-label">Created by</label>
            <input
              type="text"
              placeholder="Enter your Name"
              className="form-control custom-input"
              name="createdBy"
              value={createdBy}
              onChange={(e) => setCreatedBy(e.target.value)}
            />
            {errors.createdBy && (
              <span
                style={{ color: "red", fontSize: "12px", marginLeft: "5px" }}
              >
                {errors.createdBy}
              </span>
            )}
          </div>

          <div
            style={{
              flex: "1 1 33%",
              marginBottom: "30px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label className="form-label">Model Category</label>
            <select
              className="form-control custom-select"
              name="modelCategory"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Select Model Category</option>
              <option value="genie">Genie</option>
              <option value="speechtotext">Speech-to-text-Model (STT)</option>
              <option value="texttospeech">Text-to-Speech-Model (TTS)</option>
              <option value="entitydetection">Token Entity Model (TEM)</option>
              <option value="signlanguagetranslate">
                Sign Language Translate Model (SLT)
              </option>
              <option value="fingerspellcheck">Finger Spell Check Model (FSC)</option>
            </select>
            {errors.category && (
              <span
                style={{ color: "red", fontSize: "12px", marginLeft: "5px" }}
              >
                {errors.category}
              </span>
            )}
          </div>

          <div
            style={{
              flex: "1 1 33%",
              marginBottom: "30px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label className="form-label">Upload Model</label>
            <div
              onClick={handleModelAddIconClick}
              style={{
                flex: "1 1 33%",
                margin: "5px",
                display: "flex",
                flexDirection: "column",
                border: "1px dashed #667085",
                width: "86%",
                borderRadius: "5px",
                alignItems: "center",
                textAlign: "center",
                padding: "5px",
                cursor: "pointer",
              }}
            >
              {modelFile ? (
                <>
                  <p className="para-style" style={{ color: "blue" }}>
                    {modelFile.name}
                  </p>
                  <p className="para-style">
                    Browse and choose the .h5 file you want to upload from your
                    computer
                  </p>
                  <img src={addIcon} alt="addicon" />
                </>
              ) : (
                <>
                  <img src={hfive} alt="icon" />
                  <p className="para-style">
                    Browse and choose the model file you want to upload from your
                    computer
                  </p>
                  <img src={addIcon} alt="addicon" />
                </>
              )}
            </div>
            <input
              type="file"
              id="modelInput"
              style={{ display: "none" }}
              onChange={handleModelChange}
            />
            {errors.modelFile && (
              <span
                style={{ color: "red", fontSize: "12px", marginLeft: "5px" }}
              >
                {errors.modelFile}
              </span>
            )}
          </div>

          <div
            style={{
              flex: "1 1 33%",
              marginBottom: "30px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label className="form-label">Upload Meta data</label>
            <div
              onClick={handleMetaAddIconClick}
              style={{
                flex: "1 1 33%",
                margin: "5px",
                display: "flex",
                flexDirection: "column",
                border: "1px dashed #667085",
                width: "86%",
                borderRadius: "5px",
                alignItems: "center",
                textAlign: "center",
                padding: "5px",
                cursor: "pointer",
              }}
            >
              {metaFile ? (
                <>
                  <p className="para-style" style={{ color: "blue" }}>
                    {metaFile.name}
                  </p>
                  <p className="para-style">
                    Browse and choose the model .json file you want to upload
                    from your computer
                  </p>
                  <img src={addIcon} alt="addicon" />
                </>
              ) : (
                <>
                  <img src={metadata} alt="icon" />
                  <p className="para-style">
                    Browse and choose the model .json file you want to upload
                    from your computer
                  </p>
                  <img src={addIcon} alt="addicon" />
                </>
              )}
            </div>
            <input
              type="file"
              id="metaInput"
              style={{ display: "none" }}
              onChange={handleMetaChange}
            />
            {errors.metaFile && (
              <span
                style={{ color: "red", fontSize: "12px", marginLeft: "5px" }}
              >
                {errors.metaFile}
              </span>
            )}
          </div>

          <div
            style={{
              flex: "1 1 33%",
              marginBottom: "30px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label className="form-label">Upload Model Summary</label>
            <div
              onClick={handleSummaryAddIconClick}
              style={{
                flex: "1 1 33%",
                margin: "5px",
                display: "flex",
                flexDirection: "column",
                border: "1px dashed #667085",
                width: "86%",
                borderRadius: "5px",
                alignItems: "center",
                textAlign: "center",
                padding: "5px",
                cursor: "pointer",
              }}
            >
              {summaryFile ? (
                <>
                  <p className="para-style" style={{ color: "blue" }}>
                    {summaryFile.name}
                  </p>
                  <p className="para-style">
                    Browse and choose the image file you want to upload from
                    your computer
                  </p>
                  <img src={addIcon} alt="addicon" />
                </>
              ) : (
                <>
                  <img src={summary} alt="icon" />
                  <p className="para-style">
                    Browse and choose the image file you want to upload from
                    your computer
                  </p>
                  <img src={addIcon} alt="addicon" />
                </>
              )}
            </div>
            <input
              type="file"
              id="summaryInput"
              style={{ display: "none" }}
              onChange={handleSummaryChange}
            />
            {errors.summaryFile && (
              <span
                style={{ color: "red", fontSize: "12px", marginLeft: "5px" }}
              >
                {errors.summaryFile}
              </span>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flex: "1 1 33%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button className="btm-btn-cls" onClick={handleClose}>
              Close
            </button>
            <button className="btm-btn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddModel;
