import React from "react";
import "./displayModelInfo.css";

const Notes = ({ model }) => (
  <div style={{border:'1px solid #ccc', borderRadius:'5px', padding:'10px', boxSizing:'border-box', maxWidth: '100%', whiteSpace:'normal'}}>
    {model.note ? (
      <div>
        <p style={{padding:'5px', fontSize:'16px', color:'#667085', fontWeight:'500', lineHeight:'25px', margin:'0', wordBreak: 'break-word'}}>{model.note}</p>
      </div>
    ) : (
      <p>No notes available</p>
    )}
  </div>
);  

export default Notes;
