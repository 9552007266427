import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import addIcon from "../../Assets/plusIcon.svg";
import AddModel from "../Add_Model/AddModel";
import Modal from "@material-ui/core/Modal";
import "./tabs.css";

const NavigableTabs = ({ tabs, refresh }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);

  const handleTabClick = (index) => {
    setActiveTab(index);
    tabs[index].onClick();
  };

  const handleAdd = () => {
    setOpen(true);
  };

  const handleClose = (e, reason) => {
    // console.log(reason);
    if (!(reason === "backdropClick")) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <div className="navigable-tabs">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "50%",
        }}
      >
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab ${index === activeTab ? "active" : ""}`}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
            <span
              style={{
                marginLeft: "10px",
                border: "0px solid gray",
                backgroundColor: "#F2F2F2",
                padding: "2px",
              }}
            >
              {tab.count}
            </span>
            {index === activeTab && (
              <div
                style={{
                  height: "5px",
                  backgroundColor: "#4386FB", //set color for active highlighting
                  marginTop: "7px",
                }}
              />
            )}
          </div>
        ))}
      </div>

      <div>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#4386FB",
            color: "#fff",
          }}
          startIcon={
            <img
              src={addIcon}
              alt="Custom Icon"
              style={{ width: "24px", height: "24px", marginLeft: "20%" }}
            />
          }
          onClick={handleAdd}
        >
          Add Model
        </Button>
      </div>

      {/* Render the modal component when Add Model button is clicked */}
      <Modal
        onClose={handleClose}
        open={open}
        style={{
          backgroundColor: "white",
          background: "white",
          width: "80%",
          margin: "5% 0px 0px 10%",
        }}
      >
        <AddModel handleClose={handleClose} refresh={refresh} />
      </Modal>
    </div>
  );
};

export default NavigableTabs;
