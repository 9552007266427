import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ArtifactStore from "./Components/Root_Artifact/ArtifactStore";
import PasswordPrompt from "./Components/Password_Model/PasswordPrompt";
import pwdBg from '../src/Assets/authentication.png'

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthOnNavigation = () => {
      const auth = sessionStorage.getItem('authenticated');
      setIsAuthenticated(!!auth);
    };

    checkAuthOnNavigation();

    window.addEventListener('popstate', checkAuthOnNavigation);
    return () => window.removeEventListener('popstate', checkAuthOnNavigation);
  }, []);

  const handleAuthenticationSuccess = () => {
    sessionStorage.setItem('authenticated', 'true');
    setIsAuthenticated(true);
  };

  return (
    <Router>
      {!isAuthenticated ? (
      <div style={{backgroundImage: `url(${pwdBg})`,width:'100%', height:'100vh'}}>
        <PasswordPrompt onPasswordSuccess={handleAuthenticationSuccess} />
      </div>
      ) : (
        <Routes>
          <Route path="/" element={<ArtifactStore />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;

