import React from "react";
import "./displayModelInfo.css";

  // Helper function to safely parse JSON
  const safeParseJSON = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (e) {
      console.error("Failed to parse JSON", e);
      return {};
    }
  };

  // Dynamically find the primary key for model details
  const findPrimaryKey = (metadata) => {
    const commonKeys = ["slt", "genie", "stt","tem","tts"]; // Extend this list based on observed keys
    return Object.keys(metadata).find(key => commonKeys.includes(key)) || Object.keys(metadata)[0];
  };

  // Helper function to find benchmark key dynamically
const findBenchmarkKey = (metadata) => {
  // List of potential keys that usually relate to benchmark information
  const possibleKeys = ["benchmarks_results", "benchmarks"];

  if (!metadata) {
    console.error("Metadata is undefined or null.");
    return null; 
  }
  
  for (let key of possibleKeys) {
    if (metadata.hasOwnProperty(key)) {
      return key;
    }
  }
  
  // If no common key is found, fall back to a safe default or null
  return null; // or you can return Object.keys(metadata)[0] as a last resort
};

const BenchmarkInfo = ({ model }) =>  {
    // Parse the model_metadata JSON string to an object
    // const metadata = JSON.parse(model.model_metadata).slt.benchmarks_results;
    const metadata = safeParseJSON(model.model_metadata);
    const primaryKey = findPrimaryKey(metadata);
    const benchmarkKey = findBenchmarkKey(metadata[primaryKey]) || 'benchmarks_results'; // Use a default key if none found

      // Handle cases where benchmark data might be absent
  if (!benchmarkKey || !metadata[primaryKey][benchmarkKey]) {
    return <div>No Benchmark Data Available</div>;
  }

    return (
      <div style={{ display: "flex", flexDirection: "column", overflow:'hidden'}}>
        {/* {Object.entries(metadata).map(([key, value]) => { */}
         {Object.entries(metadata[primaryKey][benchmarkKey] || {}).map(([key, value]) => {
          // Check if value is an object to handle nested data
          if (typeof value === 'object' && value !== null) {
            return (
              <div key={key}>
                <label>{key.replace(/_/g, " ")}</label>
                {Object.entries(value).map(([subKey, subValue]) => (
                  <p className="detail-values" key={subKey}>
                    <label>{subKey.replace(/_/g, " ")}</label> <span style={{color:'#667085'}}>{subValue.toString()}</span>
                  </p>
                ))}
              </div>
            );
          } else {
            return (
              <p className="detail-values" key={key}>
                <label>{key.replace(/_/g, " ")}</label> <span style={{color:'#667085'}}>{value.toString()}</span>
              </p>
            );
          }
        })}
      </div>
    );
  };

export default BenchmarkInfo;



