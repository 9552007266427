import React from "react";
import Navbar from "../NavBar/Navbar";
import ModelArtifact from "./ModelArtifact";

const ArtifactStore = () => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <Navbar />
      <ModelArtifact />
    </div>
  );
};

export default ArtifactStore;
