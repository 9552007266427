import React, { useState, useEffect } from "react";
import "./modelTable.css";
import "../Model_Table/modelTable.css";
import downloadIcon from "../../Assets/download.svg";
import notesIcon from "../../Assets/notes.svg";
import axios from "axios";

const ModelTable = ({
  models,
  isLoading,
  onRowClick,
  selectedModel,
  fetchModel,
  onNoteSave,
}) => {
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [currentModel, setCurrentModel] = useState(null);
  const [note, setNote] = useState("");
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    // Calculate initial modal position
    const modalWidth = 200; // Example width of the modal
    const modalHeight = 300; // Example height of the modal
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const initialX = (screenWidth - modalWidth) / 2;
    const initialY = (screenHeight - modalHeight) / 2;
    setModalPosition({ x: initialX, y: initialY });
  }, []);

  const downloadModel = async (s3Path, event) => {
    event.stopPropagation(); // Prevent row click event when downloading
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/download-url`,
        {
          params: {
            file_key: s3Path,
          },
        }
      );
      const data = response.data;
      if (response.status === 200) {
        const link = document.createElement("a");
        link.href = data.url;
        link.download = s3Path.split("/").pop(); // Set the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Failed to get download URL", data.message);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  //convert UTC timestamp to local time
  function convertToLocalTime(utcTimestamp) {
    // console.log('utc time...',utcTimestamp)
    const date = new Date(utcTimestamp + "Z"); // Add 'Z' to indicate UTC time
    return date.toLocaleString(); // Converts to local time string
  }

    // Helper function to safely parse JSON
    const safeParseJSON = (jsonString) => {
      try {
        return JSON.parse(jsonString);
      } catch (e) {
        console.error("Failed to parse JSON", e);
        return {};
      }
    };
  
    // Dynamically find the primary key for model details
    const findPrimaryKey = (metadata) => {
      const commonKeys = ["slt", "modelInfo", "details","benchmarks_results"]; // Extend this list based on observed keys
      return Object.keys(metadata).find(key => commonKeys.includes(key)) || Object.keys(metadata)[0];
    };

    const findBenchmarkKey = (metadata) => {
      // List of potential keys that usually relate to benchmark information
      const possibleKeys = ["benchmarks_results", "performance_data", "results", "benchmarks"];
      
      // Iterate through possible keys and return the first match found in metadata
      for (let key of possibleKeys) {
        if (metadata.hasOwnProperty(key)) {
          return key;
        }
      }
      
      // If no common key is found, fall back to a safe default or null
      return null; // or you can return Object.keys(metadata)[0] as a last resort
    };

    // Function to access nested benchmark results dynamically
    const getBenchmarkDetails = (metadata, primaryKey) => {
      const benchmarkKey = findBenchmarkKey(metadata[primaryKey]);
      return metadata[primaryKey][benchmarkKey] || {};
    };

  // Handle the click event of the notes icon
  const openNotesModal = (model, event) => {
    event.stopPropagation();
    setCurrentModel(model);
    setNote(model.note || "");
    setIsNotesModalOpen(true);
  };

  // Handle saving the note to the database
  const saveNote = async () => {
    try {
      const existingNote = currentModel.note || "";
      if (existingNote && existingNote.trim() !== "" && existingNote !== note) {
        const overwriteConfirmation = window.confirm(
          "Are you sure to save the changes?"
        );
        if (!overwriteConfirmation) return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/save-note`,
        {
          model_id: currentModel.id,
          note: note,
        }
      );
      if (response.status === 200) {
        console.log("Note saved successfully");
        fetchModel();
        setIsNotesModalOpen(false);
      } else {
        console.error("Failed to save note", response.data.message);
      }
    } catch (error) {
      console.error("Error saving note:", error);
    }
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
    const startX = event.pageX - modalPosition.x;
    const startY = event.pageY - modalPosition.y;

    let modalWidth = 200;
    let modalHeight = 300;

    const handleMouseMove = (event) => {
      let newPosX = event.pageX - startX;
      let newPosY = event.pageY - startY;

      // Check if the modal will go beyond the screen boundaries
      if (newPosX < 0) newPosX = 0; // Left boundary
      if (newPosY < 0) newPosY = 0; // Top boundary
      if (newPosX + modalWidth > window.innerWidth) {
        newPosX = window.innerWidth - modalWidth; // Right boundary
      }
      if (newPosY + modalHeight > window.innerHeight) {
        newPosY = window.innerHeight - modalHeight; // Bottom boundary
      }

      setModalPosition({ x: newPosX, y: newPosY });
    };

    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <div className="model-table-container">
      {isLoading ? (
        <div className="loading">Loading, Please wait...</div>
      ) : (
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr style={{ border: "1px solid red" }}>
                <th style={{ textAlign: "left" }}>Model Name & Version</th>
                <th>Created On</th>
                <th>Total Classes</th>
                <th>Accuracy</th>
                <th>F1 Score</th>
                <th>Uploaded By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {models.length === 0 ? (
                <tr>
                  <td colSpan="7" className="no-data">
                    No data available
                  </td>
                </tr>
              ) : (
                models.map((model, index) => {
                  const metadata = safeParseJSON(model.model_metadata);
                  const primaryKey = findPrimaryKey(metadata);
                  const benchmarks = getBenchmarkDetails(metadata, primaryKey);
                  return (
                    <tr
                      key={index}
                      onClick={() => onRowClick(model)}
                      className={
                        selectedModel && model.id === selectedModel.id
                          ? "selected-row"
                          : ""
                      }
                    >
                      <td
                        style={{ textAlign: "left" }}
                      >{`${model.model_name} (${metadata[primaryKey].version})`}</td>
                      <td style={{ textAlign: "center" }}>
                        {convertToLocalTime(model.timestamp)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {benchmarks.total_classes || "N/A"}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {benchmarks.accuracy || "N/A"}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {benchmarks.f1_score || "N/A"}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {model.created_by}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <button
                          className="btn btn-primary"
                          aria-label="edit"
                          onClick={(e) => downloadModel(model.s3_model_path, e)}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            cursor: "pointer",
                          }}
                        >
                          <img src={downloadIcon} alt="Edit" title="download" />
                        </button>
                        <button
                          className="btn btn-primary"
                          aria-label="notes"
                          onClick={(e) => openNotesModal(model, e)}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            cursor: "pointer",
                          }}
                        >
                          <img src={notesIcon} alt="Notes" title="notes" />
                        </button>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      )}
      {isNotesModalOpen && (
        <div
          className="notes-modal"
          style={{ left: modalPosition.x, top: modalPosition.y}}
          onMouseDown={handleMouseDown}
        >
          <div className="notes-modal-content" onMouseDown={handleMouseDown}>
            <p style={{ fontSize: "18px", fontWeight: "bold", margin: "0px" }}>
              {currentModel && currentModel.model_name}
            </p>
            <textarea
              className="notes-textarea"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder="Write your notes here..."
              onMouseDown={(e) => e.stopPropagation()}
            />
            <div
              style={{ display: "flex", flexDirection: "row", width: "95%" }}
            >
              <button
                onClick={() => setIsNotesModalOpen(false)}
                className="btn btn-secondary"
                style={{
                  backgroundColor: "#fff",
                  color: "#4386FB",
                  border: "1px solid #4386FB",
                  fontWeight: "500",
                }}
              >
                Cancel
              </button>
              <button
                onClick={saveNote}
                className="btn btn-primary"
                style={{ border: "1px solid #4386FB", fontWeight: "500" }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModelTable;
