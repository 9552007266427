import React from "react";
import "./loader.css";

const Loader = () => {
  return (
    <div className="loader">
    <div class="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div style={{marginTop:'15%', fontSize: "20px", fontWeight: 600, color:'#fff'  }}>
    Uploading Model, Please wait ...
    </div>
    
    </div>

    // <div className="loader">
    //   <div className="spinner"></div>
    //   <p
    //     style={{
    //       marginTop: "21%",
    //       marginLeft: "3%",
    //       fontSize: "20px",
    //       color: "#fff",
    //       fontWeight: 600,
    //     }}
    //   >
    //     Uploading Model, Please wait ...
    //   </p>
    // </div>
  );
};

export default Loader;
