import React from "react";
import NavigableTabs from "./NavigableTabs";
import "./tabs.css";

const ModelCategory = ({ refresh, onCategoryChange, categoryCounts }) => {
  const categoryMap = {
    All: "All",
    Genie: "genie",
    STT: "speechtotext",
    TEM: "entitydetection",
    TTS: "texttospeech",
    SLT: "signlanguagetranslate",
    FSC: "fingerspellcheck",
  };

  const tabs = [
    {
      label: "All",
      count: Object.values(categoryCounts).reduce((a, b) => a + b, 0),
      onClick: () => onCategoryChange(categoryMap["All"]),
    },
    {
      label: "Genie",
      count: categoryCounts["genie"] || 0,
      onClick: () => onCategoryChange(categoryMap["Genie"]),
    },
    {
      label: "STT",
      count: categoryCounts["speechtotext"] || 0,
      onClick: () => onCategoryChange(categoryMap["STT"]),
    },
    {
      label: "TEM",
      count: categoryCounts["entitydetection"] || 0,
      onClick: () => onCategoryChange(categoryMap["TEM"]),
    },
    {
      label: "TTS",
      count: categoryCounts["texttospeech"] || 0,
      onClick: () => onCategoryChange(categoryMap["TTS"]),
    },
    {
      label: "SLT",
      count: categoryCounts["signlanguagetranslate"] || 0,
      onClick: () => onCategoryChange(categoryMap["SLT"]),
    },
    {
      label: "FSC",
      count: categoryCounts["fingerspellcheck"] || 0,
      onClick: () => onCategoryChange(categoryMap["FSC"]),
    },
  ];

  return (
    <div className="model-category">
      <NavigableTabs tabs={tabs} refresh={refresh} />
    </div>
  );
};

export default ModelCategory;
